import { request } from 'graphql-request'
import debounce from 'debounce-promise'
import memoize from 'promise-memoize'

const groupCategories = (results) =>
  results.reduce((grouped, result) => {
    if (!grouped[result.category]) grouped[result.category] = []
    grouped[result.category].push(result)
    return grouped
  }, {})

const staticContent = [
  {
    text: 'Car Offers',
    url: '/cars/offers'
  },
  {
    text: 'Van Offers',
    url: '/vans/offers',
    keywords: ['van', 'offer']
  },
  {
    text: 'Car Offers',
    url: '/cars/offers',
    keywords: ['car', 'offer']
  },
  {
    text: 'Careers',
    url: '/careers',
    keywords: ['jobs']
  }
]

const searchStatic = (phrase) => {
  const cleanPhrase = phrase.toLowerCase()
  return staticContent
    .filter(
      (content) =>
        content.text.includes(cleanPhrase) ||
        (content.keywords && content.keywords.includes(cleanPhrase))
    )
    .map((content) => ({ ...content, category: 'Page ' }))
}

const search = debounce(
  memoize(
    async (phrase, limit = 25) => {
      const query = `{
    omni(query: ${JSON.stringify(phrase)}, limit: ${limit}) {
      category
      text
      imageUrl
      url
    }
  }`
      const results = await request(
        `${process.env.GATSBY_API_URL}/graphql`,
        query
      )
      return groupCategories([...searchStatic(phrase), ...results.omni])
    },
    { maxAge: 300e3 }
  ),
  250
)

export default search
