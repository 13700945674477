import { trackEvent, trackPage } from '../../../lib/tracker'
export const ACCIDENT_MODAL_OPEN = 'ACCIDENT_MODAL_OPEN'
export const ACCIDENT_MODAL_CLOSE = 'ACCIDENT_MODAL_CLOSE'

export const open = () => {
  trackPage('/accident')
  trackEvent('HadAnAccident', 'Clicked')
  return { type: ACCIDENT_MODAL_OPEN }
}
export const close = () => {
  trackPage()
  return { type: ACCIDENT_MODAL_CLOSE }
}
