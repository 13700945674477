import { NUMBER_SET } from './actions'
import toLink from '../../lib/phone-number-linker'

const globalNumber = '0330 008 2220'

const initialState = {
  id: [],
  phoneNumber: globalNumber,
  link: toLink(globalNumber)
}

const reducer = (state = initialState, action) => {
  let phoneNumber
  let calltracksClass
  switch (action.type) {
    case NUMBER_SET:
      phoneNumber = action.setting.phoneNumber || globalNumber
      calltracksClass = action.setting.calltracksClass || ''
      return {
        ...state,
        phoneNumber,
        calltracksClass,
        link: toLink(phoneNumber)
      }
  }
  return state
}

export default reducer
