import React from 'react'
import { Provider } from 'react-redux'
import createStore from './src/state/store'
import { GraphQL, GraphQLProvider } from 'graphql-react'
import FavouritesProvider from './src/context/favourites'
import ReactDOM from 'react-dom'

// Zero config GraphQL client that manages the cache.
const graphql = new GraphQL()

export const wrapRootElement = ({ element }) => {
  const store = createStore()
  const ConnectedRootElement = (
    <GraphQLProvider graphql={graphql}>
      <FavouritesProvider>
        <Provider store={store}>{element}</Provider>
      </FavouritesProvider>
    </GraphQLProvider>
  )
  return ConnectedRootElement
}

// GA
export const onRouteUpdate = ({ location }) => {
  if (typeof ga === `function`) {
    if (
      location &&
      typeof window.excludeGAPaths !== `undefined` &&
      window.excludeGAPaths.some((rx) => rx.test(location.pathname))
    ) {
      return
    }
    window.ga(
      `set`,
      `page`,
      location ? location.pathname + location.search + location.hash : undefined
    )
    window.ga(`gtm1.send`, `pageview`)
  }
}

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
