import { TEST_DRIVE_MODAL_OPEN, TEST_DRIVE_MODAL_CLOSE } from './actions'

const initialState = {
  show: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TEST_DRIVE_MODAL_OPEN:
      return {
        ...state,
        show: true
      }
    case TEST_DRIVE_MODAL_CLOSE:
      return {
        ...state,
        show: false
      }
  }
  return {
    ...state
  }
}

export default reducer
