import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import search from './search/reducer'
import accidentModal from './accident-modal/reducer'
import testDriveModal from './test-drive-modal/reducer'
import phoneNumber from './phone-number/reducer'

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window !== 'undefined' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'MyApp',
        actionsBlacklist: ['REDUX_STORAGE_SAVE']
      })
    : compose

const enhancer = composeEnhancers(applyMiddleware(thunk))

export default () =>
  createStore(
    combineReducers({
      search,
      accidentModal,
      testDriveModal,
      phoneNumber
    }),
    enhancer
  )
