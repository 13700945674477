import searchFn from './lib/search'

export const SEARCH_SET_SEARCH = 'SEARCH_SET_SEARCH'
export const SEARCH_SET_CURRENT_SEARCH_TERM = 'SEARCH_SET_CURRENT_SEARCH_TERM'
export const SEARCH_CLEAR = 'SEARCH_CLEAR'
export const SEARCH_CLEAR_TERM = 'SEARCH_CLEAR_TERM'
export const SEARCH_SEARCH_FETCH = 'SEARCH_SEARCH_FETCH'
export const SEARCH_SEARCH_SUCCESS = 'SEARCH_SEARCH_SUCCESS'
export const SEARCH_SEARCH_FAILURE = 'SEARCH_SEARCH_FAILURE'

export const setSearchTerm = (searchTerm) => ({
  type: SEARCH_SET_SEARCH,
  searchTerm
})

export const clearSearchTerm = () => ({
  type: SEARCH_CLEAR_TERM
})

export const setCurrentSearchTerm = (searchTerm) => ({
  type: SEARCH_SET_CURRENT_SEARCH_TERM,
  searchTerm
})

export const search = () => (dispatch, getState) => {
  searchFn(getState().search.searchTerm)
    .then((data) => dispatch({ type: SEARCH_SEARCH_SUCCESS, results: data }))
    .catch((error) => dispatch({ type: SEARCH_SEARCH_FAILURE, error }))
  dispatch({ type: SEARCH_CLEAR })
  dispatch({ type: SEARCH_SEARCH_FETCH })
}
