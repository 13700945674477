export const trackPage = (virtualPath = '') => {
  if (typeof ga !== 'function') return false
  window.ga(
    `set`,
    `page`,
    document && document.location
      ? (document.location.pathname + virtualPath).replace(/\/+/g, '/') +
          document.location.search +
          document.location.hash
      : undefined
  )
  window.ga(`gtm1.send`, `pageview`)
}

export const trackEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  fieldsObject
) => {
  if (typeof ga !== 'function') return false
  window.ga(
    'gtm1.send',
    'event',
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
    fieldsObject
  )

  if (!window.dataLayer) return false

  // gtm event
  window.dataLayer.push({
    event: 'gtm.event',
    eventName: `${eventCategory} ${eventAction} ${eventLabel}`,
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    value: eventValue,
    ...fieldsObject
  })
}
