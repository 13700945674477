import { trackPage } from '../../../lib/tracker'

export const TEST_DRIVE_MODAL_OPEN = 'TEST_DRIVE_MODAL_OPEN'
export const TEST_DRIVE_MODAL_CLOSE = 'TEST_DRIVE_MODAL_CLOSE'

export const open = () => {
  trackPage('/test-drive')
  return { type: TEST_DRIVE_MODAL_OPEN }
}

export const close = () => {
  // trackEvent()
  return { type: TEST_DRIVE_MODAL_CLOSE }
}
