import { parse } from 'querystring'
import {
  SEARCH_SET_SEARCH,
  SEARCH_SET_CURRENT_SEARCH_TERM,
  SEARCH_CLEAR,
  SEARCH_CLEAR_TERM,
  SEARCH_SEARCH_FETCH,
  SEARCH_SEARCH_SUCCESS,
  SEARCH_SEARCH_FAILURE
} from './actions'
let query = {}
if (typeof window !== 'undefined')
  query = parse(window.location.search.substring(1))

const initialState = {
  searchResults: [],
  searchTerm: query.term || '',
  loading: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_CLEAR:
      return {
        ...state,
        searchTerm: '',
        searchResults: []
      }
    case SEARCH_CLEAR_TERM:
      return {
        ...state,
        searchTerm: ''
      }
    case SEARCH_SET_SEARCH:
      return {
        ...state,
        searchTerm: action.searchTerm
      }
    case SEARCH_SET_CURRENT_SEARCH_TERM:
      return {
        ...state,
        currentSearchTerm: action.searchTerm
      }
    case SEARCH_SEARCH_FETCH:
      return {
        ...state,
        error: action.error,
        loading: false
      }
    case SEARCH_SEARCH_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        searchResults: []
      }
    case SEARCH_SEARCH_SUCCESS:
      return {
        ...state,
        searchResults: action.results,
        loading: false
      }
  }
  return state
}

export default reducer
