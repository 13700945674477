import React, { createContext } from 'react'
import { GraphQLClient } from 'graphql-request'

import createDebug from 'debug'
import memoize from 'promise-memoize'

const debug = createDebug('favourites')

const client = new GraphQLClient(`${process.env.GATSBY_API_URL}/graphql`, {
  credentials: 'include'
})

const graphql = memoize(
  (query, variables) => client.request(query, variables),
  { maxAge: 1000 }
)

const favourites = `{
  getFavourites {
    favourites
  }
  user {
    _id
    firstName
    lastName
  }
}`

const logoutMutation = `mutation {
  logout {
    loggedOut
  }
}`

export const FavouritesContext = createContext('favourites')

export function withFavouritesContext(Component) {
  return function WrapperComponent(props) {
    return (
      <FavouritesContext.Consumer>
        {(state) => <Component {...props} context={state} />}
      </FavouritesContext.Consumer>
    )
  }
}

class FavouritesProvider extends React.Component {
  constructor() {
    super()
    this.state = {
      favourites: [],
      user: null
    }

    this.isFavourited = this.isFavourited.bind(this)
    this.toggle = this.toggle.bind(this)
    this.logout = this.logout.bind(this)
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
  }

  get length() {
    return this.state.favourites.length
  }

  componentDidMount() {
    this.load()

    if (typeof window !== 'undefined') {
      document.addEventListener('visibilitychange', this.handleVisibilityChange)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      document.removeEventListener(
        'visibilitychange',
        this.handleVisibilityChange
      )
    }
  }

  handleVisibilityChange() {
    console.log(document.visibilityState)
    if (document.visibilityState === 'visible') {
      this.load()
    }
  }

  clear() {
    this.setState({ favourites: [] })
  }

  async logout() {
    debug('Logging out')
    await client.request(logoutMutation)
    await this.load()
  }

  async load() {
    debug('Loading saved favourites')
    try {
      const { getFavourites, user } = await graphql(favourites)
      this.setState({ favourites: getFavourites.favourites, user })
    } catch (error) {
      console.log(error)
      debug('Could not parse favourites', error)
    }
  }

  save() {
    debug('Saving favourites', this.state.favourites)
    this.persist()
  }

  async persist() {
    const mutation = `mutation {
      favourites(
        favourites: ${JSON.stringify(this.state.favourites)}
        ) {
        favourites
      }
    }`

    try {
      await client.request(mutation)
      debug('Successfully persisted favourites')
    } catch (error) {
      debug('Could not persist favourites', error, error.response)
    }
  }

  isFavourited(id) {
    return this.state.favourites.includes(id)
  }

  toggle(id) {
    debug('Toggling', id)
    let favourites = this.state.favourites
    if (this.isFavourited(id)) {
      favourites = favourites.filter((_id) => _id !== id)
    } else {
      favourites.push(id)
    }
    this.setState({ favourites }, () => this.save())
  }

  render() {
    const { favourites, user } = this.state
    const length = this.length
    const isFavourited = this.isFavourited
    const toggle = this.toggle
    const logout = this.logout
    return (
      <FavouritesContext.Provider
        value={{
          favourites,
          user,
          length,
          isFavourited,
          toggle,
          logout
        }}
      >
        {this.props.children}
      </FavouritesContext.Provider>
    )
  }
}

export default FavouritesProvider
